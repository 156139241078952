<template>
  <div class="intraocularTension">
    <h3>角膜内细胞</h3>
    <ul class="content-sr padding-20">
      <li></li>
      <li>细胞密度</li>
      <li>平均细胞面积</li>
      <li>六边形细胞比例</li>
      <li>变异系数</li>
    </ul>
    <ul class="content-sr">
      <li>OD</li>
      <li><input type="text"><span>cells/mm²</span></li>
      <li><input type="text"><span>um²</span></li>
      <li><input type="text"><span>%</span></li>
      <li><input type="text"><span>%</span></li>
    </ul>
    <ul class="content-sr padding-20">
      <li>OS</li>
      <li><input type="text"><span>cells/mm²</span></li>
      <li><input type="text"><span>um²</span></li>
      <li><input type="text"><span>%</span></li>
      <li><input type="text"><span>%</span></li>
    </ul>
  </div>
</template>

<script>
export default {
 name: "intraocularTension"
}
</script>

<style scoped lang="scss">
  .intraocularTension {
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  h3 {padding-top: 20px}
  .padding-20{padding-top: 15px;}
  .content-sr {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 10vw;
      margin-right: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      span {
        font-size: 11px;
        position: absolute;
        left: calc(10vw + 2px);
      }
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
    }
    li:nth-child(1) {
      width: 4vw;
      justify-content: start;
      margin-right: 5px;
    }
  }
</style>