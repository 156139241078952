<template>
  <div class="expertClinicHome">
    <ul class="left">
      <li class="bt"><p></p><span>智能处方</span><p></p></li>
      <li class="el-icon-caret-right" :class="idx==1?'xz':''" @click="teb(1)" >眼部诊断 <i v-if="zjzs.Recomm1" class="el-icon-check"></i></li>
      <li class="el-icon-caret-right" :class="idx==2?'xz':''" @click="teb(2)">视光诊断 <i v-if="zjzs.Recomm2" class="el-icon-check"></i></li>
      <li class="el-icon-caret-right" :class="idx==3?'xz':''" @click="teb(3)">视光处方方案 <i v-if="zjzs.Recomm3" class="el-icon-check"></i></li>
      <li class="el-icon-caret-right" :class="idx==4?'xz':''" @click="$router.push({path: '/corneaContact/Iframe?type=7'})">电子病历 <i class="el-icon-check" v-if="zjzs.S_OP_MR"></i></li>
      <li class="bt"><p></p><span>专家会诊</span><p></p></li>
      <li class="el-icon-caret-right" :class="idx==5?'xz':''" @click="gozj">专家会诊</li>
      <li class="el-icon-caret-right" :class="idx==6?'xz':''" @click="showset=true">设置</li>
    </ul>
    <div class="rightp" ref="clinic">
      <EyeDiagnosis ref="eyed" class="border-b"></EyeDiagnosis>
      <ViewDiagnosis class="border-b"></ViewDiagnosis>
      <ViewPlan class="border-b"></ViewPlan>
    </div>
    <div class="save">
<!--      <div class="bnt"> <el-button type="primary" @click="saveData">{{bt}}保存</el-button></div>-->
<!--      <div v-if="isbsd && idx==2" @click="bsdtj" class="bnt"> <el-button type="primary">宝视达提交</el-button></div>-->
<!--      <div v-if="idx == 3" class="bnt" @click="$alert('请使用移动端操作', '提示')"> <el-button type="primary">复查时间</el-button></div>-->
<!--      <div v-if="idx == 3 || idx == 1" class="bnt" @click="qm"> <el-button type="primary">{{idx==3?'视光师签名':'医生签名'}}</el-button></div>-->
    </div>

    <el-dialog
        title="请选择设置项目"
        :visible.sync="showset"
        :close-on-click-modal="true"
        width="40%"
        :show-close="false"
        center>
      <div style="display: flex;justify-content: center;">
        <el-button type="primary" @click="setPz('setLY')">蓝牙设置</el-button>
        <el-button type="primary" @click="setPz('setIP')">IP设置</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="请选择设置项目"
        :visible.sync="showset"
        :close-on-click-modal="true"
        width="40%"
        :show-close="false"
        center>
      <div style="display: flex;justify-content: center;">
        <el-button type="primary" @click="setPz('setLY')">蓝牙设置</el-button>
        <el-button type="primary" @click="setPz('setIP')">IP设置</el-button>
      </div>
    </el-dialog>

    <ExpertList v-if="showlt" @closea = "showlt = false"></ExpertList>
<!--    <el-dialog-->
<!--        title="请选择专家"-->
<!--        :visible.sync=""-->
<!--        :close-on-click-modal="true"-->
<!--        width="900px"-->
<!--        :show-close="false"-->
<!--        style="border-radius: 20px;"-->
<!--        center>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import EyeDiagnosis from '@/views/expertClinic/eyeDiagnosis'
import ViewDiagnosis from '@/views/expertClinic/viewDiagnosis'
import ViewPlan from '@/views/expertClinic/viewPlan'
import ExpertList from "@/views/expertClinic/expertList"
export default {
  name: "expertClinicHome",
  data() {
    return {
      idx: 1,
      bt: '眼部诊断',
      btMap: new Map(),
      save: new Map(),
      showset: false,
      isbsd: false,
      zjzs: {
        Recomm1: false,
        Recomm2: false,
        Recomm3: false,
        S_OP_MR: false
      },
      showys: {

      },
      showlt: false,
      fcid: '',
      ysList: [],
      gdtcd: 0,
    }
  },
  watch: {
    idx: function (n, o) {
      this.bt = this.btMap.get(n)
    },
    "$store.state.isShow.keyh": {
      handler(n, o) {
        // console.dir(document.body.offsetHeight)
        // console.dir(this.$refs.boxs)
        if (n > document.body.offsetHeight - 290) {
          this.$refs.clinic.scrollTop = this.$refs.clinic.scrollTop + (n - (document.body.offsetHeight - 290)) + 30
        }
      }
    },
    "$store.state.isbc.zjzs": {
      deep: true,
      handler: function (n ,o) {
        // console.log( n )
        this.zjzs = JSON.parse(JSON.stringify(n))
      }
    },
  },
  created() {
    let arrConfig = this.$store.state.users.user.Company.ListCompanyConfig
    if (arrConfig && arrConfig.length > 0) {
      for (let i = 0; i < arrConfig.length; i++) {
        if (arrConfig[i].BCKey == "BSD_Sync" && arrConfig[i].BCValue == 1) this.isbsd = true

        if (arrConfig[i].BCKey == 'ECCId') this.fcid = arrConfig[i].BCValue
      }
    }

    if (!this.$store.state.users.employees) {
      this.$router.push({path: '/employeesLanding'})
      return this.$alert('员工未登录！请登录', '提示')
    }

    this._api.publicApi.getExaminationFinish()
    .then(res => {
      if (res.GetResult && res.GetResult.ChooseStep) {
        let arrs = res.GetResult.ChooseStep.split(','),
            obj = {}
        for (let i = 0; i < arrs.length; i++) {
          if (arrs[i]) obj[arrs[i]] = arrs[i]
        }
        if (obj.Recomm1) this.$store.commit('isbc/upZjzs', {key: 'Recomm1', val: true})
        if (obj.Recomm2) this.$store.commit('isbc/upZjzs', {key: 'Recomm2', val: true})
        if (obj.Recomm3) this.$store.commit('isbc/upZjzs', {key: 'Recomm3', val: true})
        if (obj.S_OP_MR) this.$store.commit('isbc/upZjzs', {key: 'S_OP_MR', val: true})

        // this.zjzs.Recomm1 = obj.Recomm1?true:false
        // this.zjzs.Recomm2 = obj.Recomm2?true:false
        // this.zjzs.Recomm3 = obj.Recomm3?true:false
        // this.zjzs.S_OP_MR = obj.S_OP_MR?true:false
      }
    })
  },
  methods: {
    teb(idx) {
      this.idx = idx
      this.$refs.clinic.scrollTop = this.getDivH(idx-1, 0)
    },
    boxbh() {
      let top = this.$refs.clinic.scrollTop
      this.allys = document.getElementsByClassName('border-b')
      this.clScrollTop(top)
    },
    getDivH(idx, type=1)  {
      if (idx < 1) return 0
      let h = 0
      for (let i = 0; i < idx; i++) {
        h += this.allys[i].offsetHeight
      }
      return h
    },

    clScrollTop(h) {
      if (h < this.getDivH(1)) {
        this.idx = 1
      }else if (h > this.getDivH(1) && h < this.getDivH(2)*0.8) {
        this.idx = 2
      }else if (h > this.getDivH(2)*0.8) {
        this.idx = 3
      }
    },

    saveData() {
      if (this.save.get(this.idx)) {
        // console.log(this.save.get(this.idx))
        this.save.get(this.idx)()
      }
    },

    saveEyeZd() {

      if (this.$store.state.expertClinic.isSave !== 'Success') {
        return this.$alert(this.$store.state.expertClinic.isSave, '提示')
        .then(res => {
          this.$router.push({path: '/ifame2', query: {type: 0}})
        })
      }

      let arr = []
      let datas = this.$store.state.expertClinic.expertClinicData.S_OP_Json
      arr[0] = {
        key: 'CheckResult_Diagnosis',
        value: datas.CheckResult_Diagnosis
      }
      arr[1] = {
        key: 'CheckResult_DealSuggestion',
        value: datas.CheckResult_DealSuggestion
      }
      arr[2] = {
        key: 'CheckResult_Comment',
        value: datas.CheckResult_Comment
      }
      arr[3] = {
        key: 'CheckResult_CustomConclusion',
        value: datas.CheckResult_CustomConclusion
      }
      arr[4] = {
        key: 'CheckResult_DealSuggestion_Des',
        value: datas.CheckResult_DealSuggestion_Des
      }
      arr[5] = {
        key: 'ICD_EyeCheck',
        value: datas.ICD_EyeCheck
      }

      this.$refs.eyed.saveywbc()

      this._api.expertClinic.saveExpertClinic(arr)
    },
    saveViewZd() {
      if (this.$store.state.expertClinic.isSave !== 'Success') {
        return this.$alert('去充值', '提示')
            .then(res => {
              this.$router.push({path: '/ifame2', query: {type: 0}})
            })
      }
      let arr = []
      let datas = this.$store.state.expertClinic.expertClinicData.S_OP_Json
      arr[0] = {
        key: 'Refractive_Diagnosis',
        value: datas.Refractive_Diagnosis
      }
      arr[1] = {
        key: 'Refractive_Solution',
        value: datas.Refractive_Solution
      }
      arr[2] = {
        key: 'Refractive_Comment',
        value: datas.Refractive_Comment
      }
      arr[3] = {
        key: 'StringRefractive_SuggestionFromAPP',
        value: datas.StringRefractive_SuggestionFromAPP
      }
      arr[4] = {
        key: 'CheckResult_DealSuggestion_Des',
        value: datas.CheckResult_DealSuggestion_Des
      }
      arr[5] = {
        key: 'ICD_Diagnosis',
        value: datas.ICD_Diagnosis
      }
      this._api.expertClinic.saveExpertClinic(arr, '视光诊断')
    },

    qm() {
      if (this.idx == 3) {
        this._api.expertClinic.sgsqm()
        .then(res => {
          // console.log(res)
          if (typeof res === 'object') {
            if (res.InsertResult) {
              this.$store.commit('physicianVisits/upissgqm', false)
              this.$alert('提交签名成功', '提示')
            }else {
              this.$alert('提交签名失败', '提示')
            }
          }
        })
      }else {
        this._api.expertClinic.ysqm()
            .then(res => {
              if (typeof res === 'object') {
                if (res.InsertResult) {
                  this.$store.commit('physicianVisits/upIsqm', false)
                  this.$alert('提交签名成功', '提示')
                }else {
                  this.$alert('提交签名失败', '提示')
                }
              }
            })
      }
    },

    gozj() {
      if (!this.$store.state.users.employees) {
        this.$router.push({path: '/employeesLanding'})
        return this.$alert('员工未登录！请登录', '提示')
      }

      this.showlt = true
      // this._api.userCurdApi.getEmployeesListLT( this.fcid)
      // .then(res => {
      //   if (res.GetListResult && res.GetListResult.length) {
      //     this.ysList = [...res.GetListResult]
      //   }
      //   // console.log(this.ysList)
      // })
    },


    setPz(key) {
      if (window.androids) {
        window.androids[key]()
      }
    },

    bsdtj() {
      let ling = this.$loading({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
      })
      this._api.expertClinic.BSDtj()
      .then(res => {
        ling.close()
        if (res) {
          this.$alert(res.displaymsg, '提示')
        }
      })
    },

  },
  mounted() {

    this.$refs.clinic.addEventListener('scroll', this._jl(this.boxbh, 300))
    this.allys = document.getElementsByClassName('border-b')
    this.btMap.set(1, '眼部诊断')
    this.btMap.set(2, '视光诊断')
    this.btMap.set(3, '视光处方')

    this.save.set(1, this.saveEyeZd)
    this.save.set(2, this.saveViewZd)
    this.save.set(3, this._api.expertClinic.saveViewPlan)
  },
  components: {
    EyeDiagnosis,
    ViewDiagnosis,
    ViewPlan,
    ExpertList
  }
}
</script>

<style scoped lang="scss">
  .expertClinicHome {
    width: 100vw;
    display: flex;
    justify-content: start;
    height: 76vh;
    overflow: hidden;
  }
  .border-b {
    border-bottom: 1px dotted #5a5959;
    padding-bottom: 0.3rem;
    margin-bottom: 1rem !important;
  }
  .rightp {
    width: 87vw;
    height: 76vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .left {
    width: 12vw;
    font-size: 14px;
    text-align: left;
    border-right: 1px solid #bcbaba;
    overflow: hidden;
    overflow-y: auto;
    li {
      padding: 0.2rem 0.2rem;
      display: block;
      cursor: pointer;
      &:hover{
        background: #e7e7e7;
      }
    }
    .xz {background: #e7e7e7}
    .bt {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        width: 20%;
        height: 1px;
        background: #bcbaba;
        @media (max-width: 1200px) {
          width: 15%;
        }
      }
      span {padding: 0 0.1rem}
    }
  }
  .left::-webkit-scrollbar { width: 0 !important }
  //.rightp::-webkit-scrollbar { width: 0 !important }
  .save {
    position: fixed;
    right: 0;
    bottom: 14vh;
    text-align: right;
    .bnt {
      margin: .2rem 0;
    }
  }


</style>
