<template>
  <div class="mgs" @click="showlt = false">
    <div class="mgbox" @click.stop="" >
      <h3>请选择专家</h3>
      <div class="saixuan">
        <el-select class="xzbox" v-model="zjmcval" filterable placeholder="专家姓名">
          <el-option
              v-for="item in zjmc"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-input class="xzbox" v-model="gzdwval" placeholder="工作单位"></el-input>
        <el-select class="xzbox" v-model="zcval" filterable placeholder="职称">
          <el-option
              v-for="item in zc"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-select class="xzbox" v-model="zyval" filterable placeholder="专业">
          <el-option
              v-for="item in zy"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <div class="chazao" @click="sousuo">
          <i class="el-icon-search"></i>
          <span>搜索</span>
        </div>
      </div>
      <div class="zjlb" ref="zjbox">
        <!--          <div v-for="item in ysList" class="zjlb-box">-->
        <!--            <img :src="'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/'+item.ExpertstrImgUrl" alt="">-->
        <!--            <div class="mz">-->
        <!--              <div style="padding-bottom: 5px;">-->
        <!--                <span>{{item.ExpertName}}</span>-->
        <!--                <span>{{item.ExpertWorkTitle}}</span>-->
        <!--              </div>-->
        <!--              <div>{{item.ExpertCompany}}</div>-->
        <!--            </div>-->
        <!--            <div class="jianjie">专业简介：{{item.ExpertIntroduce}}</div>-->
        <!--            <div class="hjzj" @click="hujiao(item.ExpertTel)">呼叫专家</div>-->
        <!--          </div>-->
        <i @click.stop="zygd('z')" class="el-icon-arrow-left zjt"></i>
        <i @click.stop="zygd('y')" class="el-icon-arrow-right yjt"></i>
        <div style="display: flex;align-items: center;" ref="listbox">
          <div class="zjlb-box" v-for="item in ysList">
            <img v-if="item.ExpertstrImgUrl" :src="'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/'+item.ExpertstrImgUrl" alt="">
            <img v-if="!item.ExpertstrImgUrl" src="../../assets/img/mrtx.png" alt="">
            <div class="mz">
              <div style="padding-bottom: 5px;">
                <span>{{item.ExpertName}}</span>
                <span>{{item.ExpertWorkTitle}}</span>
              </div>
              <div style="height: 20px;">{{item.ExpertCompany}}</div>
            </div>
            <div class="zyjj">
              <p>专业简介：</p>
              <div>{{item.ExpertIntroduce}}</div>
            </div>
            <div class="hjzj" @click="hujiao(item.ExpertTel)">呼叫专家</div>
          </div>
        </div>
      </div>
      <i class="el-icon-circle-close gb" @click="gbs"></i>
      <!--        <p class="gb" @click="showlt = false">关闭</p>-->
    </div>
  </div>
</template>

<script>
import {Loading} from "element-ui";

export default {
  name: "expertList",
  data() {
    return {
      zjmc: [],
      gzdw: [],
      zc: [
        {
          value: '',
          label: ''
        },
        {
          value: '教授/主任医师',
          label: '教授/主任医师'
        },
        {
          value: '副教授/副主任医师',
          label: '副教授/副主任医师',
        },
        {
          value: '主治医师',
          label: '主治医师'
        },
        {
          value: '视光师',
          label: '视光师'
        }
      ],
      zy: [
        {
          value: '',
          label: ''
        },
        {
          value: '视光科',
          label: '视光科'
        },
        {
          value: '小儿眼科',
          label: '小儿眼科'
        },
        {
          value: '斜弱视科',
          label: '斜弱视科'
        },
        {
          value: '眼整形学科',
          label: '眼整形学科'
        },
        {
          value: '角膜学科',
          label: '角膜学科'
        },
        {
          value: '屈光学科',
          label: '屈光学科'
        },
        {
          value: '眼底学科',
          label: '眼底学科'
        },
        {
          value: '青光眼学科',
          label: '青光眼学科'
        },
        {
          value: '白内障学科',
          label: '白内障学科'
        },
        {
          value: '老花门诊',
          label: '老花门诊'
        },
        {
          value: '综合门诊',
          label: '综合门诊'
        },
        {
          value: 'VIP门诊/院长门诊',
          label: 'VIP门诊/院长门诊'
        },
        {
          value: '干眼门诊',
          label: '干眼门诊'
        }
      ],
      zjmcval: '',
      gzdwval: '',
      zcval: '',
      zyval: '',
      ysList: [],
      fcid: '',
      itemYsList: []
    }
  },
  created() {
    let arrConfig = this.$store.state.users.user.Company.ListCompanyConfig
    if (arrConfig && arrConfig.length > 0) {
      for (let i = 0; i < arrConfig.length; i++) {
        if (arrConfig[i].BCKey == "BSD_Sync" && arrConfig[i].BCValue == 1) this.isbsd = true

        if (arrConfig[i].BCKey == 'ECCId') this.fcid = arrConfig[i].BCValue
      }
    }

    let loadingInstance = Loading.service({
      background: 'rgba(0, 0, 0, 0.7)',
      text: '加载中...'
    });

    this._api.userCurdApi.getEXSX()
    .then(res => {
      if (res.OtherResult) {
        this.zc = [...this.getarr(res.OtherResult.ExpertWorkTitle)]
        this.zy = [...this.getarr(res.OtherResult.ExpertExpertise)]
      }
    })


    this._api.userCurdApi.getEmployeesListLT(this.fcid)
        .then(res => {
          loadingInstance.close()
          if (res.GetListResult && res.GetListResult.length) {
            this.ysList = [...res.GetListResult]
            this.itemYsList = [...res.GetListResult]
            let arr = []
            for (let i = 0; i < this.ysList.length; i++) {
              arr.push({
                value: this.ysList[i].ExpertId,
                label: this.ysList[i].ExpertName
              })
              this.zjmc = [{
                value: '',
                label:''
              },...arr]
              // console.log(this.zjmc)
            }
          }
          // console.log(this.ysList)
        })
  },
  methods: {
    zygd(type) {
      let seep = 80
      if (type == 'z') seep = -80
      this.$refs.zjbox.scrollLeft = this.$refs.zjbox.scrollLeft + seep

      if (type == 'y' && this.gdtcd == this.$refs.zjbox.scrollLeft) {
        this.$refs.zjbox.scrollLeft = 0
      }

      this.gdtcd = this.$refs.zjbox.scrollLeft
    },
    gbs() {
      // console.log(2222)
      this.$emit('closea', 0)
    },
    getarr(arr) {
      let arrs = []
      if (!arr) return arrs
      for (let i = 0; i < arr.length; i++) {
        arrs.push({
          value: arr[i],
          label: arr[i]
        })
      }
      return arrs
    },
    sousuo() {
      if (!this.gzdwval && !this.zcval && !this.zyval && this.zjmcval) {
        this.ysList = [this.itemYsList.find(item => item.ExpertId == this.zjmcval)]
        return
      }
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.userCurdApi.getEmployeesListLT(this.fcid, this.gzdwval, this.zcval, this.zyval)
          .then(res => {
            loadingInstance.close()
            if (res.GetListResult && res.GetListResult.length) {
              this.ysList = [...res.GetListResult]
              let arr = []
              for (let i = 0; i < this.ysList.length; i++) {
                arr.push({
                  value: this.ysList[i].ExpertId,
                  label: this.ysList[i].ExpertName
                })
                this.zjmc = [{
                  value: '',
                  label:''
                },...arr]
                // console.log(this.zjmc)
              }
            }else {
              this.ysList = []
            }
            // console.log(this.ysList)
          })
    },
    hujiao(id) {
      if (window.androids) {
        let obj = {
          ChooseAddTime: this.$store.state.users.csObj.ChooseAddTime,
          ChooseId: this.$store.state.users.csObj.ChooseId,
          ChooseShopId: this.$store.state.users.csObj.ChooseShopId,
          ChooseUserId: this.$store.state.users.csObj.ChooseUserId,
          ChooseExpertId: this.$store.state.users.csObj.ChooseExpertId,
          ChooseCustomerId: this.$store.state.users.csObj.ChooseCustomerId,
          ExpertId: this.$store.state.users.employees.ExpertId,
          TargetExpertId: id,
          ExpertName: this.$store.state.users.employees.ExpertName,
          CustomerName: this.$store.state.users.CsUser.CustomerName
        }
        window.androids.getExData(JSON.stringify(obj))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.zjlb {
  width: 100%;
  height: 460px;
  overflow: hidden;
  overflow-x: auto;
  //overflow-y: auto;
  .zjlb-box {
    display: inline-block;
    padding: 15px 25px;
    border-radius: 30px;
    background: rgba(250, 250, 250, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    box-shadow: 0px 5px 4px  rgba(0, 0, 0, 0.15);
    margin: 0 20px;
    img {
      width: 162px;
      height: 178px;
      border-radius: 10px;
      border: 1px solid rgba(156, 156, 156, 1);
    }
    .mz {
      padding-top: 10px;
      //width: 14em;
      text-align: center;
      span:nth-child(1) {
        font-weight: bold;
        font-size: 16px;
        padding-right: 20px;
      }
    }
    //.jianjie {
    //  width: 30em;
    //  height: 40px;
    //  display: block;
    //  display: -webkit-box;
    //  overflow: hidden;
    //  -webkit-line-clamp: 2;
    //  -webkit-box-orient: vertical;
    //  text-overflow: ellipsis;
    //  text-align: left;
    //}
    .zyjj {
      text-align: left;
      padding-top: 18px;
      p {
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 5px;
      }
      div {
        width: 20em;
        height: 60px;
        margin-bottom: 30px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
    .hjzj {
      width: 4em;
      border: 1px solid rgba(26, 161, 152, 1);
      height: 35px;
      border-radius: 5px;
      line-height: 35px;
      padding: 0 12px;
      color: rgba(26, 161, 152, 1);
      margin: 0 auto;
      //margin-left: 30px;
      cursor: pointer;
    }
  }
  i {
    font-size: 50px;
    font-weight: bold;
    position: absolute;
    color: #bababa;
    cursor: pointer;
  }
  .zjt {
    left: -5px;
    top: 250px;
  }
  .yjt {
    right: -5px;
    top: 250px;
  }
}
.mgs {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.7);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .mgbox {
    background: #FFFFFF;
    width: 80vw;
    border-radius: 20px;
    padding: 10px 40px;
    position: relative;
    h3 {padding: 20px 0;}
    .gb {
      position: absolute;
      //font-size: 25px;
      color: #a5a5a5;
      //font-weight: bold;
      //transform: rotate(0deg);
      top: 10px;
      right: 10px;
      border-radius: 5px;
      //border: 1px solid #ff0101;
      padding: 5px;
      cursor: pointer;
      font-size: 30px;
    }
  }
}
.saixuan {
  display: flex;
  justify-content: start;
  padding-bottom: 20px;
  padding-left: 20px;
  .xzbox {
    width: 14vw;
    max-width: 220px;
    margin-right: 15px;
  }
  .chazao {
    background: rgba(209, 209, 209, 1);
    color: rgba(54, 54, 54, 1);
    line-height: 40px;
    border-radius: 10px;
    padding: 0 20px;
    cursor: pointer;
    i {
      font-weight: bold;
      font-size: 16px;
      margin-right: 10px;
    }
  }
}
</style>
